export const tags = ['Blog']

// TODO - Is article meta going to pull article data as well?
export const siteName = "White Owl Education"

export const blogPost = {
    id: "2021-02-27-00",
    created_at: "2021-02-27T00:00:00.000-00:00",
    updated_at: "2021-02-27T00:00:00.000-00:00",
    page: {
        url: "https://www.whiteowleducation.com/machine-learning-mastery-course-outline/"
    },
    author: {
        type: "Person",
        name: "White Owl Education",
        author_image_url: "https://whiteowleducation.com/images/authors/white-owl-education/white-owl-education-author-face.jpg",
        pathname: "images/authors/white-owl-education/white-owl-education-author-face.jpg",
        author_image_description: "Picture of Ralph Brooks, CEO of White Owl Education"
    },
    title: "Roadmap to Using Artificial Intelligence in Production",
    publisher: {
        name: siteName,
        url: "https://whiteowleducation.com/images/theme/header/logo/wo-logo-transparent.png"
    },
    description: "Annoucing a new course on deploying machine learning models into production",
    image: {
        url: "https://whiteowleducation.com/images/blog/2021/02/27/card-main-image.jpg",
        description: "White Owl Education presents Machine Learning Mastery"
    },
    internal_featured_image: {
        pathname: "images/blog/2021/02/27/machine-learning-mastery.jpg"
    },
    keywords: tags[0],
    featured: "no",
    read_time_minutes : "2",
    visibility: "private"
}