import React from 'react'
import { Helmet } from 'react-helmet'
import config from '../../../utils/siteConfig'

// Given blog information, insert the ld+json for blog pages directly into head with Helmet

declare interface blogPostProps {
    blogPost: {
        author: {
            type: string
            name: string
            author_image_url: string
        }
        title: string
        created_at: string
        updated_at: string
        publisher: {
            name: string
            url: string
        }
        image: {
            url: string
        }
        description: string
        // TODO: right now keywords only takes one tag this needs to be fixed
        keywords: string

    }
    canonical: string
}


export const BlogMeta: React.FunctionComponent<blogPostProps> = ({blogPost, canonical}) => {

    
    // TODO jsonLD only can take in one tag - this needs to be fixed
    // TOOD shareimagewidth and shareimageheight may not be right

    const jsonLd = {
        "@context": `https://schema.org/`,
        "@type": `Article`,
        author: {
            "@type": blogPost.author.type,
            name: blogPost.author.name,
            image: blogPost.author.author_image_url
        },
        keywords: blogPost.keywords,
        headline: blogPost.title,
        url: canonical,
        datePublished: blogPost.created_at,
        dateModified: blogPost.updated_at,
        image: {
            "@type": `ImageObject`,
            url: blogPost.image.url,
            width: config.shareImageWidth,
            height: config.shareImageHeight
        },
        description: blogPost.description,
        publisher: {
            "@type": `Organization`,
            name: blogPost.publisher.name,
            logo: {
                "@type": `ImageObject`,
                url: blogPost.publisher.url,
                width: 60,
                height: 60,
            }
        },
        mainEntityOfPage: {
            "@type": `WebPage`,
            "@id": config.siteUrl
        },
    }

    return (
        <>
            <Helmet>
            <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
            </Helmet>
        </>
    );

};
